import * as Sentry from "@sentry/browser";

import {
  ADD_POCKET_MESSAGE,
  AVAILABLE_DROP_OFF_SERVICES,
  CREATE_TAKEBACK,
} from "../core-modules/takeback-queries";
import { ERROR, HOW } from "../core-modules/constants";
import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import { CREATE_INSTORE_TAKEBACK } from "../core-modules/takeback-queries";
import { COMPLETE_URL } from "../core-modules/urls";
import client from "../core-modules/apollo-client";
import { isValidEmail } from "../core-modules/utils";
import { useRouter } from "next/router";

export const TakebackContext = React.createContext();
export const useTakebackContext = () => useContext(TakebackContext);

function TakebackContextProvider({ children }) {
  const router = useRouter();

  // Takeback state
  const [takebackItems, setTakebackItems] = useState([]);
  const [donatedTakebackItems, setDonatedTakebackItems] = useState([]);
  const [currentStep, setCurrentStep] = useState(HOW);
  const [itemFilter, setItemFilter] = useState(null);
  const [userDetailsHasErrors, setUserDetailsHasErrors] = useState(false);
  const [createTakebackError, setCreateTakebackError] = useState(false);
  const [takebackId, setTakebackId] = useState(null);
  const [userDetails, setUserDetails] = useState({
    email: {
      value: "",
      error: null,
    },
    confirmEmail: {
      value: "",
      error: null,
    },
    postcode: {
      value: "",
      error: null,
    },
    mobileNumber: {
      value: "",
      error: null,
    },
    terms: {
      value: false,
      error: null,
    },
    optOutReskinned: {
      value: false,
      error: null,
    },
    optInBrand: {
      value: false,
      error: null,
    },
    courierServiceId: {
      value: null,
      error: null,
    },
    labelType: {
      value: null,
      error: null,
    },
    ageGroup: {
      value: "",
      error: null,
    },
    gender: {
      value: "",
      error: null,
    },
  });
  const [dropOffServicesVars, setCourierServicesVars] = useState({
    postcode: "",
  });
  const [createInstoreTakebackError, setCreateInstoreTakebackError] =
    useState(false);
  const [instoreTakebackId, setInstoreTakebackId] = useState(null);
  const [showInstoreApprovalBox, setShowInstoreApprovalBox] = useState(false);

  // Lazy query for getting available courier services on postcode search
  const [
    getAvailableDropOffServices,
    {
      loading: dropOffServicesLoading,
      called: dropOffServicesCalled,
      data: dropOffServicesData,
    },
  ] = useLazyQuery(AVAILABLE_DROP_OFF_SERVICES, {
    variables: dropOffServicesVars,
    fetchPolicy: "cache-first",
  });
  // Storing the results of the courier service API call in state
  const [dropOffServices, setCourierServices] = useState([]);

  // Creating the Takeback
  const [createTakeback, { loading: takebackMutationLoading }] = useMutation(
    CREATE_TAKEBACK,
    {
      client,
      onCompleted: ({ createTakeback: takebackId }) => {
        if (takebackId) {
          setTakebackId(takebackId);
        } else {
          Sentry.captureException("Takeback creation failed.");
          setCreateTakebackError(true);
        }
      },
      onError: (error) => {
        Sentry.captureException(`Error creating takeback: ${error}`);
      },
    }
  );

  // Add a pocket message
  const [
    addPocketMessage,
    {
      loading: pocketMessageMutationLoading,
      error: pocketMessageMutationError,
      data: pocketMessageData,
    },
  ] = useMutation(ADD_POCKET_MESSAGE, {
    client,
  });

  // Creating the InstoreTakeback
  const [createInstoreTakeback, { loading: instoreTakebackMutationLoading }] =
    useMutation(CREATE_INSTORE_TAKEBACK, {
      client,
      onCompleted: ({ createInstoreTakeback: instoreTakebackId }) => {
        if (instoreTakebackId) {
          setInstoreTakebackId(instoreTakebackId);
        } else {
          Sentry.captureException("Takeback creation failed.");
          setCreateInstoreTakebackError(true);
        }
      },
      onError: (error) => {
        Sentry.captureException(`Error creating instore takeback: ${error}`);
      },
    });

  // Takeback useEffects

  useEffect(() => {
    if (takebackId) {
      router.push(`${router.query.slug}${COMPLETE_URL}/${takebackId}`);
    }
  }, [takebackId]);

  useEffect(() => {
    // The email and confirm email fields are linked, so if one has an
    // error and one changes then clear the errors for both of them
    if (userDetails.email.error || userDetails.confirmEmail.error) {
    }
    const updatedEmailFields = {
      email: {
        value: userDetails.email.value,
        error: null,
      },
      confirmEmail: {
        value: userDetails.confirmEmail.value,
        error: null,
      },
    };
    const updatedDetails = { ...userDetails, ...updatedEmailFields };
    setUserDetails(updatedDetails);
  }, [userDetails.email.value, userDetails.confirmEmail.value]);

  useEffect(() => {
    // If we get new courier service vars, execute the query again
    // If there is no postcode then don't bother
    if (dropOffServicesVars.postcode === "") return;
    // Execute the lazy query
    getAvailableDropOffServices();
    if (dropOffServicesData) {
      // If we have an error then report this to the user
      if (dropOffServicesData.availableDropOffServices.__typename === ERROR) {
        const updatedCourierServiceId = {
          courierServiceId: {
            value: null,
            error: dropOffServicesData.availableDropOffServices.message,
          },
        };
        const updatedDetails = {
          ...userDetails,
          ...updatedCourierServiceId,
        };
        setUserDetails(updatedDetails);
      } else {
        // If there is only one available service then we want to pre-select it
        if (
          dropOffServicesData.availableDropOffServices.dropOffServices
            .length === 1
        ) {
          const dropOffService =
            dropOffServicesData.availableDropOffServices.dropOffServices[0];
          handleDropOffServiceSelect(dropOffService);
        }
        // Set the results of the query in local state
        setCourierServices(
          dropOffServicesData.availableDropOffServices.dropOffServices
        );
      }
    }
  }, [dropOffServicesVars, dropOffServicesData]);

  useEffect(() => {
    // Clear the chosen courier service if the postcode changes
    setCourierServices([]);
    const updatedCourierServiceInfo = {
      courierServiceId: {
        value: null,
        error: null,
      },
      labelType: {
        value: null,
        error: null,
      },
    };
    const updatedDetails = {
      ...userDetails,
      ...updatedCourierServiceInfo,
    };
    setUserDetails(updatedDetails);
  }, [userDetails.postcode.value]);

  // Instore takeback useEffects

  useEffect(() => {
    if (showInstoreApprovalBox) {
      document.body.classList.add("overlay-open");
    } else {
      document.body.classList.remove("overlay-open");
    }
  }, [showInstoreApprovalBox]);

  useEffect(() => {
    if (instoreTakebackId) {
      router.push(
        `/${router.query.slug}/instore${COMPLETE_URL}?id=${instoreTakebackId}`
      );
    }
  }, [instoreTakebackId]);

  useEffect(() => {
    // The email and confirm email fields are linked, so if one has an
    // error and one changes then clear the errors for both of them
    if (userDetails.email.error || userDetails.confirmEmail.error) {
    }
    const updatedEmailFields = {
      email: {
        value: userDetails.email.value,
        error: null,
      },
      confirmEmail: {
        value: userDetails.confirmEmail.value,
        error: null,
      },
    };
    const updatedDetails = {
      ...userDetails,
      ...updatedEmailFields,
    };
    setUserDetails(updatedDetails);
  }, [userDetails.email.value, userDetails.confirmEmail.value]);

  // Takeback functions

  const addTakebackItem = (item) => {
    setTakebackItems([...takebackItems, item]);
  };

  const removeTakebackItemsById = (id) => {
    setTakebackItems(
      takebackItems.filter((item) => {
        return item.id !== id;
      })
    );
  };

  const incrementTakebackItem = (item) => {
    setTakebackItems([...takebackItems, item]);
  };

  const decrementTakebackItem = (item) => {
    const indexToRemove = takebackItems.lastIndexOf(item);
    const takebackItemsUpdated = takebackItems.filter(
      (_, index) => index !== indexToRemove
    );
    setTakebackItems(takebackItemsUpdated);
  };

  const addDonatedTakebackItem = (item) => {
    setDonatedTakebackItems([...donatedTakebackItems, item]);
  };

  const removeDonatedTakebackItemsById = (id) => {
    setDonatedTakebackItems(
      donatedTakebackItems.filter((item) => {
        return item.id !== id;
      })
    );
  };

  const incrementDonatedTakebackItem = (item) => {
    setDonatedTakebackItems([...donatedTakebackItems, item]);
  };

  const decrementDonatedTakebackItem = (item) => {
    const indexToRemove = donatedTakebackItems.lastIndexOf(item);
    const donatedTakebackItemsUpdated = donatedTakebackItems.filter(
      (_, index) => index !== indexToRemove
    );
    setDonatedTakebackItems(donatedTakebackItemsUpdated);
  };

  const handleSubmitTakeback = () => {
    const updatedDetails = { ...userDetails };
    let formHasErrors = false;
    // Email not provided
    if (updatedDetails.email.value === "") {
      formHasErrors = true;
      updatedDetails.email.error = "Please provide an email address";
    }
    if (updatedDetails.confirmEmail.value === "") {
      // Confirm email not provided
      formHasErrors = true;
      updatedDetails.confirmEmail.error = "Please confirm your email address";
    }
    if (
      updatedDetails.email.value !== "" &&
      updatedDetails.confirmEmail.value !== ""
    ) {
      // Email and confirm email provided but do not match
      if (updatedDetails.email.value !== updatedDetails.confirmEmail.value) {
        formHasErrors = true;
        updatedDetails.email.error =
          "Email and email confirmation do not match";
        updatedDetails.confirmEmail.error = true;
      } else if (!isValidEmail(updatedDetails.email.value)) {
        // Email and confirm email match but are not valid
        formHasErrors = true;
        updatedDetails.email.error = "Please provide a valid email address";
        updatedDetails.confirmEmail.error = true;
      }
    }
    // Postcode and therefore courier service are not provided
    if (updatedDetails.postcode.value === "") {
      formHasErrors = true;
      updatedDetails.postcode.error =
        "Please enter your postcode and choose a drop off partner";
    }
    // Postcode has been provided but courier service has not been selected
    if (
      updatedDetails.postcode.value !== "" &&
      !updatedDetails.courierServiceId.value
    ) {
      formHasErrors = true;
      updatedDetails.postcode.error = "Please select a drop off partner";
    }
    // Mobile number has not been provided
    if (updatedDetails.mobileNumber.value === "") {
      formHasErrors = true;
      updatedDetails.mobileNumber.error = "Please provide a phone number";
    }
    // Mobile number is too short or does not contain any numbers
    if (
      updatedDetails.mobileNumber.value !== "" &&
      (updatedDetails.mobileNumber.value.length < 9 ||
        !/\d/.test(updatedDetails.mobileNumber.value))
    ) {
      formHasErrors = true;
      updatedDetails.mobileNumber.error = "Please provide a valid phone number";
    }
    // Terms and conditions have not been accepted
    if (!updatedDetails.terms.value) {
      formHasErrors = true;
      updatedDetails.terms.error =
        "Please read and accept the terms & conditions";
    }

    if (formHasErrors) {
      setUserDetails(updatedDetails);
      setUserDetailsHasErrors(true);
    } else {
      let variables = {
        email: userDetails.email.value,
        mobileNumber: userDetails.mobileNumber.value,
        optOutReskinned: userDetails.optOutReskinned.value,
        optInBrand: userDetails.optInBrand.value,
        courierServiceId: userDetails.courierServiceId.value,
        labelType: userDetails.labelType.value,
        takebackItems: takebackItems.map((item) => {
          return {
            brandItemId: item.id,
            returnReason: item.reason ? item.reason : "",
          };
        }),
        donationItemIds: donatedTakebackItems.map((item) => {
          return item.id;
        }),
        ageGroup: userDetails.ageGroup.value,
        gender: userDetails.gender.value,
        postcode: userDetails.postcode.value,
      };

      createTakeback({
        variables,
      });
    }
  };

  const getDropOffServices = () => {
    // Set the vars for the services query on click, because a new query will be executed
    // every time the vars change
    const vars = {
      postcode: userDetails.postcode.value,
    };
    setCourierServicesVars(vars);
  };

  const handleDropOffServiceSelect = (dropOffService) => {
    const updatedDropOffServiceFields = {
      courierServiceId: {
        value: dropOffService.courierServiceId,
        error: null,
      },
      labelType: {
        value: dropOffService.labelType,
        error: null,
      },
      postcode: {
        value: userDetails.postcode.value,
        error: null,
      },
    };
    const updatedDetails = {
      ...userDetails,
      ...updatedDropOffServiceFields,
    };
    setUserDetails(updatedDetails);
  };

  // Instore takeback functions

  const handleSubmitInstoreTakeback = () => {
    const updatedInstoreDetailsForm = { ...userDetails };
    let formHasErrors = false;
    // Email not provided
    if (updatedInstoreDetailsForm.email.value === "") {
      formHasErrors = true;
      updatedInstoreDetailsForm.email.error = "Please provide an email address";
    }
    if (!isValidEmail(updatedInstoreDetailsForm.email.value)) {
      updatedInstoreDetailsForm.email.error =
        "Please make sure your email address is valid";
    }
    if (updatedInstoreDetailsForm.confirmEmail.value === "") {
      // Confirm email not provided
      formHasErrors = true;
      updatedInstoreDetailsForm.confirmEmail.error =
        "Please confirm your email address";
    }
    if (
      updatedInstoreDetailsForm.email.value !== "" &&
      updatedInstoreDetailsForm.confirmEmail.value !== ""
    ) {
      // Email and confirm email provided but do not match
      if (
        updatedInstoreDetailsForm.email.value !==
        updatedInstoreDetailsForm.confirmEmail.value
      ) {
        formHasErrors = true;
        updatedInstoreDetailsForm.email.error =
          "Email and email confirmation do not match";
        updatedInstoreDetailsForm.confirmEmail.error = true;
      } else if (
        !updatedInstoreDetailsForm.email.value.includes("@") ||
        !updatedInstoreDetailsForm.email.value.includes(".")
      ) {
        // Email and confirm email match but are not valid
        formHasErrors = true;
        updatedInstoreDetailsForm.email.error =
          "Please provide a valid email address";
        updatedInstoreDetailsForm.confirmEmail.error = true;
      }
    }
    // Terms and conditions have not been accepted
    if (!updatedInstoreDetailsForm.terms.value) {
      formHasErrors = true;
      updatedInstoreDetailsForm.terms.error =
        "Please read and accept the terms & conditions";
    }

    if (formHasErrors) {
      setUserDetails(updatedInstoreDetailsForm);
      setUserDetailsHasErrors(true);
    } else {
      setShowInstoreApprovalBox(true);
    }
  };

  function handleApproveInstoreTakeback(storePin, numberOfItems) {
    setShowInstoreApprovalBox(false);
    const brandItemIds = takebackItems.map((item) => {
      return item.id;
    });
    const donationItemIds = donatedTakebackItems.map((item) => {
      return item.id;
    });
    let variables = {
      brandSlug: router.query.slug,
      email: userDetails.email.value,
      numberOfItems: numberOfItems,
      optOutReskinned: userDetails.optOutReskinned.value,
      optInBrand: userDetails.optInBrand.value,
      storePin: storePin,
      brandItemIds,
      donationItemIds,
    };

    createInstoreTakeback({
      variables,
    });
  }

  return (
    <TakebackContext.Provider
      value={{
        takebackItems,
        addTakebackItem,
        currentStep,
        setCurrentStep,
        itemFilter,
        setItemFilter,
        removeTakebackItemsById,
        incrementTakebackItem,
        decrementTakebackItem,
        addDonatedTakebackItem,
        removeDonatedTakebackItemsById,
        incrementDonatedTakebackItem,
        decrementDonatedTakebackItem,
        handleSubmitTakeback,
        donatedTakebackItems,
        userDetails,
        setUserDetails,
        dropOffServices,
        getDropOffServices,
        dropOffServicesLoading,
        dropOffServicesCalled,
        handleDropOffServiceSelect,
        addPocketMessage,
        pocketMessageMutationError,
        pocketMessageMutationLoading,
        pocketMessageData,
        userDetailsHasErrors,
        setUserDetailsHasErrors,
        createTakebackError,
        setCreateTakebackError,
        takebackMutationLoading,
        takebackId,
        userDetailsHasErrors,
        setUserDetailsHasErrors,
        createInstoreTakebackError,
        setCreateInstoreTakebackError,
        instoreTakebackId,
        setInstoreTakebackId,
        showInstoreApprovalBox,
        setShowInstoreApprovalBox,
        userDetails,
        setUserDetails,
        handleApproveInstoreTakeback,
        handleSubmitInstoreTakeback,
        setTakebackItems,
      }}
    >
      {children}
    </TakebackContext.Provider>
  );
}

export default TakebackContextProvider;
