import { FIXED_PERCENTAGE, ITEM_BASED } from "./constants";

export function isTouch() {
  // const isMobile = window.navigator.userAgent.match(
  //   /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i
  // );
  // return "ontouchstart" in document.documentElement && isMobile;
  return false;
}

export function getCategories(brand) {
  const sortedCategories = brand.items
    .map((item) => {
      return item.category;
    })
    .sort((categoryA, categoryB) =>
      categoryA.priority > categoryB.priority ? 1 : -1
    );
  return [
    ...new Set(
      sortedCategories.map((category) => {
        return category.name;
      })
    ),
  ];
}

export function getTakebackValue(brand, items) {
  let tradeInValue = 0;
  if (brand.incentiveType === ITEM_BASED) {
    // If the brand uses an item-based incentive then the Takeback value will be
    // the total value of items in the basket
    items.forEach((item) => {
      tradeInValue = tradeInValue + item.value;
    });
    // If the brand has a maximum discount and total value of all the items in the basket
    // is more than the maximum allowed discount then the Takeback value will be equal to
    // the max discount
    if (brand.maxDiscount && tradeInValue > brand.maxDiscount) {
      tradeInValue = brand.maxDiscount;
    }
  } else {
    if (items.length > 0) {
      // If the brand uses a flat discount incentive then the Takeback value will
      // be equal to the amount that they offer, provided that the miniumum
      // number of qualifying items (if provided) have been added to the basket
      if (
        !brand.minItems ||
        (brand.minItems && brand.minItems - items.length < 1)
      ) {
        tradeInValue =
          brand.incentiveType === FIXED_PERCENTAGE
            ? brand.fixedPercentageAmount
            : brand.fixedValueAmount;
      }
    }
  }
  return `${
    brand.incentiveType === FIXED_PERCENTAGE ? "" : "£"
  }${tradeInValue}${brand.incentiveType === FIXED_PERCENTAGE ? "%" : ""}`;
}

export function isValidEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email)) {
    return true;
  }
  return false;
}
