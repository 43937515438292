const config = {
  test: {
    API_BASE: "http://localhost:8000",
  },
  local: {
    API_BASE: "http://localhost:8000",
  },
  development: {
    API_BASE: "https://development.backend.reskinned.clothing",
  },
  staging: {
    API_BASE: "https://staging.backend.reskinned.clothing",
  },
  production: {
    API_BASE: "https://api.reskinned.clothing",
  },
};

export default function getConfig(key) {
  const env = process.env.REACT_APP_ENVIRON || "local";
  return config[env][key];
}
